// Generated by Framer (4f5e97b)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {g6rZ01hew: {hover: true}, XPC4VNVAw: {hover: true}};

const cycleOrder = ["XPC4VNVAw", "g6rZ01hew"];

const serializationHash = "framer-9XvhY"

const variantClassNames = {g6rZ01hew: "framer-v-d3s3hh", XPC4VNVAw: "framer-v-17q3ulq"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {onDark: "g6rZ01hew", onLight: "XPC4VNVAw"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "XPC4VNVAw"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "XPC4VNVAw", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-9XvhY", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={"https://www.facebook.com/whiteui.store"} openInNewTab><Image {...restProps} as={"a"} background={{alt: "Facebook", fit: "fit", intrinsicHeight: 64, intrinsicWidth: 64, pixelHeight: 64, pixelWidth: 64, src: "https://framerusercontent.com/images/F0wxdQSbevbreVhTAKkyb5gT24.svg"}} className={`${cx("framer-17q3ulq", className)} framer-73scm0`} data-framer-name={"onLight"} layoutDependency={layoutDependency} layoutId={"XPC4VNVAw"} ref={ref ?? ref1} style={{opacity: 1, ...style}} variants={{"g6rZ01hew-hover": {opacity: 0.8}, "XPC4VNVAw-hover": {opacity: 0.8}}} {...addPropertyOverrides({"g6rZ01hew-hover": {"data-framer-name": undefined}, "XPC4VNVAw-hover": {"data-framer-name": undefined}, g6rZ01hew: {"data-framer-name": "onDark", background: {alt: "Facebook", fit: "fit", intrinsicHeight: 96, intrinsicWidth: 96, pixelHeight: 96, pixelWidth: 96, src: "https://framerusercontent.com/images/ON59t1CnyQNBofW9xOpV6wW5EFg.png"}}}, baseVariant, gestureVariant)}/></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-9XvhY [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-9XvhY .framer-73scm0 { display: block; }", ".framer-9XvhY .framer-17q3ulq { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 32px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; text-decoration: none; width: 32px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-9XvhY .framer-17q3ulq { gap: 0px; } .framer-9XvhY .framer-17q3ulq > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-9XvhY .framer-17q3ulq > :first-child { margin-left: 0px; } .framer-9XvhY .framer-17q3ulq > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 32
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"g6rZ01hew":{"layout":["fixed","fixed"]},"lzvHSdvwa":{"layout":["fixed","fixed"]},"EtoE5MD9L":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 */
const FramerZkntaCpP_: React.ComponentType<Props> = withCSS(Component, css, "framer-9XvhY") as typeof Component;
export default FramerZkntaCpP_;

FramerZkntaCpP_.displayName = "Social Icons / Facebook";

FramerZkntaCpP_.defaultProps = {height: 32, width: 32};

addPropertyControls(FramerZkntaCpP_, {variant: {options: ["XPC4VNVAw", "g6rZ01hew"], optionTitles: ["onLight", "onDark"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerZkntaCpP_, [])